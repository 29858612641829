import { ActionContext, ActionTree } from "vuex";

import Team from "@/types/Team";

import { State } from "@/store/state";
import { Mutations, MutationType } from "@/store/mutations";
import teams from "@/data/teams";

export enum ActionTypes {
  GetTeams = "GET_TEAMS",
  FindTeam = "FIND_TEAM",
}

type ActionArguments = Omit<ActionContext<State, State>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
};
export type Actions = {
  [ActionTypes.GetTeams](context: ActionArguments): void;
  [ActionTypes.FindTeam](context: ActionArguments, id: string): void;
};

export const actions: ActionTree<State, State> & Actions = {
  [ActionTypes.GetTeams]({ commit }) {
    commit(MutationType.SetTeams, teams);
  },
  [ActionTypes.FindTeam]({ commit, state }, id) {
    const team: Team = state.teams[id];

    if (team) {
      commit(MutationType.SetSelectedTeam, team);
    } else {
      commit(MutationType.SetSelectedTeam, null);
    }
  },
};


import { Options, Vue } from "vue-class-component";

@Options({
  name: "Skater",
  props: {
    width: {
      type: String,
      default: "78",
      required: false,
    },
    height: {
      type: String,
      default: "84",
      required: false,
    },
    color: {
      type: String,
      default: "black",
      required: false,
    },
  },
})
export default class Skater extends Vue {
  width!: string;
  height!: string;
  color!: string;
}

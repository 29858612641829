<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <img src="/assets/about-us.png" class="img-fluid" alt="O nás" />
      </div>
    </div>

    <section class="main-content">
      <h1 class="h1 text-center">Kometa. Víc než jen bruslení!</h1>

      <div class="row">
        <div class="col-md-10 offset-md-1 col-sm-12 offset-sm-0">
          <p class="kometa-pre-text text-justify">
            Naši trenéři mají dlouholeté zkušenosti nejen s výchovou synchro
            krasobruslařek od prvních krůčků na ledě ale i s trénováním sólového
            krasobruslení.
          </p>

          <p class="text-justify">
            V našich týmech bruslí jak děti, které prošly přípravkou, tak bývalí
            či stále ještě aktivní sóloví bruslaři, kteří jsou pro své
            bruslařské dovednosti důležitou a nezanedbatelnou součástí našich
            týmu.
          </p>

          <p class="text-justify">
            Díky tomu, že bruslařky vychováváme od prvních krůčků, některé z
            dívek spolu bruslí přes 10 let a pořád je bruslení baví. To
            považujeme za náš velký nesoutěžní úspěch. Vážíme si příležitosti
            formovat osobnosti dětí i po nesportovní stránce, být jim oporou při
            překlenování dospívání. Z vlastní zkušenosti víme, že zde vznikají
            kamarádství na celý život.
          </p>

          <p class="slogan">
            Miřte na měsíc. I když jej minete, skončíte mezi hvězdami.<span
              class="slogan-author"
              >Les Brown</span
            >
          </p>

          <p class="text-justify">
            Ze sportovních úspěchů si nejvíce ceníme 13. místa z prvního
            oficiálního mistrovství světa juniorů, které se konalo v roce 2013 v
            Helsinkách. Naše juniorské týmy do dnešního dne reprezentovaly
            Českou republiku na třech juniorských světových šampionátech. Rovněž
            jsme několikanásobnými mistry ČR v kategoriích mladší žačky, žačky,
            mix age a juniorky a vicemistryně ČR v seniorské kategorii.
            Každoročně se pravidelně účastníme domácích a zahraničních soutěží.
          </p>
        </div>
      </div>

      <h2 class="h2 text-center">Naše čísla</h2>

      <div class="row mb-4">
        <div class="col-md-3 col-sm-6 kometa-card">
          <div class="card">
            <div class="card-body"><p class="kometa-card-number">20</p></div>
          </div>

          <p class="text-center">Kolik je Kometě let?</p>
        </div>

        <div class="col-md-3 col-sm-6 kometa-card">
          <div class="card">
            <div class="card-body"><p class="kometa-card-number">231</p></div>
          </div>

          <p class="text-center">Kolik nás bruslí a bruslilo?</p>
        </div>

        <div class="col-md-3 col-sm-6 kometa-card">
          <div class="card">
            <div class="card-body"><p class="kometa-card-number">48</p></div>
          </div>

          <p class="text-center">Kolik programů jsme složili?</p>
        </div>

        <div class="col-md-3 col-sm-6 kometa-card">
          <div class="card">
            <div class="card-body"><p class="kometa-card-number">9</p></div>
          </div>

          <p class="text-center">Kolik máme trenérů?</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AboutView",
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.slogan {
  position: relative;
  font-size: 3em;
  padding-top: 1em;

  &::before {
    content: "“";
    font-size: 64px;
    color: #bababa;
    position: absolute;
    top: 0;
  }

  &-author {
    display: block;
    font-size: 0.5em;
    color: #5a5c9d;
  }
}

.kometa-card {
  & .card {
    border-radius: 1.5rem;
    border-width: 2px;
  }

  & > .card > .card-body {
    height: 217px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > p {
    margin-top: 1rem;
  }

  &-number {
    color: #525498;
    font-size: 4rem;
    line-height: 4rem;
    margin: 0;
    padding: 0;
    text-align: center;
    align-content: center;
  }
}

@include media-breakpoint-down(xl) {
  .kometa-card {
    & > .card > .card-body {
      height: 180px;
    }

    &-number {
      font-size: 3.5rem;
      line-height: 3.5rem;
    }
  }
}

@include media-breakpoint-down(lg) {
  .kometa-card {
    & > .card > .card-body {
      height: 130px;
    }

    &-number {
      font-size: 3rem;
      line-height: 3rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  .kometa-card {
    & > .card > .card-body {
      height: 150px;
    }

    &-number {
      font-size: 3.5rem;
      line-height: 3.5rem;
    }
  }
}
</style>

import Team from "@/types/Team";

export type State = {
  teams: { [key: string]: Team };
  selectedTeam: Team | null;
};

export const state: State = {
  teams: {},
  selectedTeam: null,
};

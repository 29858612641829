<template>
  <svg
    class="kometa-icon"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="`0 0 ${widthBox} ${heightBox}`"
    :width="width"
    :height="height"
  >
    <path fill="currentColor" :d="svgPath" />
  </svg>
</template>

<script>
import { defineComponent, computed } from "vue";
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";

export default defineComponent({
  name: "icon",

  props: {
    iconName: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
  },

  setup(props) {
    const definition = computed(() =>
      findIconDefinition({
        prefix: props.type,
        iconName: props.iconName,
      })
    );

    const widthBox = computed(() => props.width | definition.value.icon[0]);
    const heightBox = computed(() => props.height | definition.value.icon[1]);
    const svgPath = computed(() => definition.value.icon[4]);

    return { widthBox, heightBox, svgPath };
  },
});
</script>

<style scoped></style>

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fas,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import Icon from "@/components/Icon.vue";

library.add(fas, faInstagram, faFacebook, faYoutube, faArrowLeft, faArrowRight);

export { Icon };

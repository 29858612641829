
import { Options, Vue } from "vue-class-component";
import { store as $store } from "@/store";
import { ActionTypes } from "@/store/actions";

import Icon from "@/components/Icon.vue";
import Navigation from "@/components/Navigation.vue";

@Options({
  name: "App",
  components: { Navigation, Icon },
})
export default class App extends Vue {
  created(): void {
    $store.dispatch(ActionTypes.GetTeams);
  }
}

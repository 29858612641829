
import { Options, Vue } from "vue-class-component";

import Member from "@/types/Member";
import Icon from "@/components/Icon.vue";
import Skater from "@/components/Skater.vue";

@Options({
  name: "MembersWithPicture",
  components: { Skater, Icon },
  props: {
    members: {
      Type: Array,
      required: true,
    },
    color: {
      Type: String,
      required: true,
    },
  },
})
export default class MembersWithPicture extends Vue {
  members!: Array<Member>;
  start = 0;
  chunk = 4;

  get membersCards(): Array<Member> {
    return this.members.slice(
      this.actualPosition,
      this.actualPosition + this.chunk
    );
  }

  get actualPosition(): number {
    return this.start * this.chunk;
  }

  get max(): number {
    return Math.ceil(this.members.length / this.chunk);
  }

  goPrev(): void {
    if (this.start === 0) {
      this.start = this.max - 1;
    } else {
      this.start = (this.start - 1) % this.max;
    }

    this.scroll();
  }

  goNext(): void {
    if (this.start === this.max - 1) {
      this.start = 0;
    } else {
      this.start = (this.start + 1) % this.max;
    }

    this.scroll();
  }

  scroll(): void {
    const element = this.$refs["kometa-clenky"] as HTMLElement;

    if (element && element.offsetTop > -1) {
      window.scroll(0, element.offsetTop);
    }
  }
}

import { GetterTree } from "vuex";
import Team from "@/types/Team";
import { State } from "@/store/state";

export enum GetterTypes {
  teams = "GET_TEAMS",
  selectedTeam = "SELECTED_TEAM",
}

export type Getters = {
  [GetterTypes.teams](state: State): { [key: string]: Team };
  [GetterTypes.selectedTeam](state: State): Team | null;
};

export const getters: GetterTree<State, State> & Getters = {
  [GetterTypes.teams](state) {
    return state.teams;
  },
  [GetterTypes.selectedTeam](state) {
    return state.selectedTeam;
  },
};


import { Options, Vue } from "vue-class-component";

import { ClickOutside } from "vue-click-outside-of";
import Logo from "@/components/Logo.vue";
import Icon from "@/components/Icon.vue";

import { store as $store } from "@/store";
import { GetterTypes } from "@/store/getters";

import Team from "@/types/Team";

@Options({
  name: "Navigation",
  components: { Logo, Icon },
  directives: { ClickOutside },
})
export default class Navigation extends Vue {
  get teams(): Team[] {
    return $store.getters[GetterTypes.teams];
  }

  hideNavigation(): void {
    const nav = this.$refs.navigation as HTMLFormElement;

    if (nav) {
      nav.classList.remove("show");
    }
  }
}

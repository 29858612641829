
import { Options, Vue } from "vue-class-component";
import { store as $store } from "@/store";
import { GetterTypes } from "@/store/getters";
import Team from "@/types/Team";

import Skater from "@/components/Skater.vue";
import Icon from "@/components/Icon.vue";
import MembersWithPicture from "@/components/MembersWithPicture.vue";
import { ActionTypes } from "@/store/actions";

@Options({
  name: "TeamView",
  components: { MembersWithPicture, Icon, Skater },
  watch: {
    teamId() {
      this.loadTeam();
    },
  },
  props: {
    teamId: {
      type: String,
      required: true,
    },
  },
})
export default class TeamView extends Vue {
  teamId!: string;

  created(): void {
    this.loadTeam();
  }

  loadTeam(): void {
    $store.dispatch(ActionTypes.FindTeam, this.teamId);
  }

  get team(): Team {
    return $store.getters[GetterTypes.selectedTeam];
  }
}

<template>
  <form v-if="!messageSend" @submit.prevent="sendContactMessage">
    <div class="row mb-3">
      <div class="col-md-4 col-sm-12">
        <input
          type="text"
          class="form-control"
          :class="{ 'is-invalid': vv.name.$error }"
          placeholder="Jméno"
          name="name"
          v-model="vv.name.$model"
        />
        <div v-if="vv.name.$error" class="invalid-feedback">
          Prosím, zadejte jméno.
        </div>
      </div>

      <div class="col-md-4 col-sm-12">
        <input
          type="tel"
          class="form-control"
          :class="{
            'is-invalid': vv.phone.$error && vv.phone.requiredIf.$invalid,
          }"
          placeholder="Telefon"
          name="phone"
          v-model="vv.phone.$model"
        />
        <div
          v-if="vv.phone.$error && vv.phone.requiredIf.$invalid"
          class="invalid-feedback"
        >
          Prosím, zadejte email nebo telefon.
        </div>
      </div>

      <div class="col-md-4 col-sm-12">
        <input
          type="email"
          class="form-control"
          :class="{
            'is-invalid':
              vv.email.$error &&
              (vv.email.email.$invalid || vv.email.requiredIf.$invalid),
          }"
          placeholder="Email"
          name="email"
          v-model="vv.email.$model"
        />
        <div
          v-if="vv.email.$error && vv.email.email.$invalid"
          class="invalid-feedback"
        >
          Prosím, zadejte email ve správném tvaru.
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <textarea
          class="form-control"
          :class="{
            'is-invalid':
              vv.message.$error &&
              (vv.message.minLength.$invalid || vv.message.required.$invalid),
          }"
          rows="3"
          placeholder="Zpráva"
          name="message"
          v-model="vv.message.$model"
        ></textarea>
        <div
          v-if="
            vv.message.$error &&
            (vv.message.minLength.$invalid || vv.message.required.$invalid)
          "
          class="invalid-feedback"
        >
          Prosím, napište zprávu.
        </div>
      </div>
    </div>

    <slot name="before-send" />

    <captcha
      class="mb-5"
      @verify="onCaptchaVerify"
      :site-key="captchaSiteKey"
    />

    <div class="row mb-3">
      <div class="col-12 d-flex justify-content-center">
        <button type="submit" class="btn kometa-btn">Odeslat</button>
      </div>
    </div>
  </form>

  <div v-else-if="messageSend && !messageSendError" class="row">
    <div class="col-md-8 col-sm-12 offset-md-2 offset-sm-0">
      <p class="alert alert-info text-center">
        Děkujeme za zprávu. Co nejdříve Vám odpovíme.
      </p>
    </div>
  </div>

  <div v-else-if="messageSend && messageSendError" class="row">
    <div class="col-md-8 col-sm-12 offset-md-2 offset-sm-0">
      <p class="alert alert-danger text-center">
        Bohužel se něco pokazilo. Zkuste odeslání kontaktu znovu později, nebo
        kontaktujte trénéra napřímo.
      </p>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength, requiredIf } from "@vuelidate/validators";
import ApiService from "@/service/apiService";
import Captcha from "@/components/Captcha.vue";

export default {
  name: "ContactForm",
  data() {
    return {
      messageSend: false,
      messageSendError: false,
      name: null,
      email: null,
      phone: null,
      message: null,
      gRecaptchaResponse: null,
    };
  },
  validations: {
    name: { required },
    email: {
      requiredIf: requiredIf(function () {
        return !this.email && !this.phone;
      }),
      email,
    },
    phone: {
      requiredIf: requiredIf(function () {
        return !this.email && !this.phone;
      }),
    },
    message: { required, minLength: minLength(10) },
  },
  setup() {
    return {
      vv: useVuelidate(),
      captchaSiteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
    };
  },
  mounted() {
    this.messageSendError = false;
  },
  methods: {
    onCaptchaVerify(token) {
      this.gRecaptchaResponse = token;
    },
    async sendContactMessage() {
      if (!this.gRecaptchaResponse) return;

      const isFormCorrect = await this.vv.$validate();
      if (!isFormCorrect) return;

      ApiService.sendContactMessage({
        name: this.name,
        email: this.email,
        phone: this.phone,
        message: this.message,
        gRecaptchaResponse: this.gRecaptchaResponse,
      })
        .catch(() => {
          this.messageSendError = true;
        })
        .finally(() => {
          this.messageSend = true;
        });
    },
  },
  components: { Captcha },
};
</script>

<style lang="scss" scoped>
.form-control {
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #5a5c9d;
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }

  &.is-invalid {
    border-color: #dc3545;

    &:focus {
      box-shadow: none;
    }
  }
}

button.kometa-btn {
  background-color: #5a5c9d;
  border: none;
}
</style>

<template>
  <svg
    width="224"
    height="60"
    viewBox="0 0 224 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M77.2848 57.1452C77.1879 57.1452 77.0909 57.1452 76.9454 57.1452C59.7818 56.9516 45.9636 42.9194 46.1091 25.7903C46.1091 25.3065 46.497 24.871 47.0303 24.871C47.5151 24.871 47.9515 25.3065 47.9515 25.7903C47.806 41.9032 60.8 55.1613 76.9939 55.3548C92.2182 55.5 104.921 44.2258 106.424 29.0806C106.473 28.5968 106.909 28.2097 107.394 28.2581C107.879 28.3065 108.267 28.7419 108.218 29.2258C106.618 45.1936 93.3333 57.1452 77.2848 57.1452Z"
      fill="#EDD550"
    />
    <path
      d="M70.8364 50.6613C70.7394 50.6613 70.6909 50.6613 70.594 50.6613C56.9697 50.5161 46.0121 39.3387 46.1576 25.7903C46.1576 25.3065 46.5455 24.871 47.0788 24.871C47.5637 24.871 48 25.3065 48 25.7903C47.9515 31.8871 50.2788 37.6452 54.5455 42C58.8121 46.3548 64.5333 48.7742 70.6424 48.871C70.7394 48.871 70.7879 48.871 70.8849 48.871C82.3273 48.871 92.0727 40.4032 93.5758 29.0806C93.6243 28.5968 94.1091 28.2097 94.594 28.3065C95.0788 28.3548 95.4182 28.8387 95.3697 29.3226C93.6727 41.5161 83.2 50.6613 70.8364 50.6613Z"
      fill="#D2563E"
    />
    <path
      d="M64.3879 44.1774C64.3394 44.1774 64.2424 44.1774 64.1939 44.1774C59.297 44.129 54.7394 42.1936 51.3454 38.7097C47.9515 35.2258 46.0606 30.629 46.1091 25.7903C46.1091 25.3065 46.497 24.871 47.0303 24.871C47.5151 24.871 47.9515 25.3065 47.9515 25.7903C47.903 30.1936 49.6 34.3065 52.6545 37.4516C55.7091 40.5968 59.8303 42.3387 64.2424 42.3871C72.1939 42.4355 79.0788 36.8226 80.5818 29.0323C80.6788 28.5484 81.1636 28.2097 81.6485 28.3065C82.1333 28.4032 82.4727 28.8871 82.3757 29.371C80.7273 37.9355 73.2121 44.1774 64.3879 44.1774Z"
      fill="#525498"
    />
    <path
      d="M0.775758 0H0.969697C1.55152 0.145161 1.89091 0.33871 1.89091 0.677419V15.9677C9.26061 11.0806 13.4788 8.37097 14.5455 7.74194H14.7879C15.2727 7.74194 15.6121 8.08065 15.7091 8.80645C15.7091 9.19355 15.0303 9.72581 13.6242 10.5C9.89091 13.0161 7.85455 14.3226 7.46667 14.371V15L15.6606 25.4032L15.7091 25.6935C15.6121 26.3226 15.2727 26.6613 14.7879 26.6613H14.6424C14.2545 26.6613 13.5273 25.8871 12.4606 24.3871L5.72121 15.9677H5.33333L1.89091 18.1452V25.8387C1.89091 26.2742 1.55152 26.5645 0.921212 26.6613C0.484848 26.6613 0.145455 26.371 0 25.7903V0.967742C0.145455 0.33871 0.387879 0 0.775758 0Z"
      fill="#525498"
    />
    <path
      d="M81.6969 8.27419C82.3272 8.41935 82.6182 8.66129 82.6182 9V10.6935C84.606 9.09677 86.4969 8.27419 88.2424 8.27419C90.5212 8.27419 92.703 9.53226 94.7879 12.0484C95.7091 10.3548 97.2606 9.14516 99.4909 8.46774L101.042 8.27419C103.806 8.27419 105.988 9.58064 107.588 12.2419C108.121 13.3548 108.364 14.4194 108.364 15.3871V25.9355C108.364 26.2742 108.073 26.5161 107.442 26.6613C106.861 26.5645 106.521 26.2742 106.521 25.7903V15.5806C106.521 13.0161 105.164 11.2742 102.448 10.3065L101.382 10.1613H100.606C98.4242 10.1613 96.7757 11.4194 95.6606 13.8871C95.5151 14.4677 95.4182 14.9516 95.4182 15.3387V25.7903C95.3212 26.4194 95.0303 26.7581 94.5454 26.7581H94.4969C93.9151 26.6613 93.5757 26.371 93.5757 25.9839V17.2742C93.5757 14.7097 93.1879 13.1129 92.4606 12.4355C91.3454 10.9839 89.9394 10.2581 88.0969 10.2581H87.903C85.4303 10.2581 83.6848 11.5645 82.6666 14.2258C82.5212 14.8548 82.4242 15.6774 82.4242 16.6452V26.0323C82.4242 26.4677 82.1333 26.7097 81.503 26.8065C81.0666 26.8065 80.7757 26.4677 80.6303 25.8387V9.43548C80.9212 8.66129 81.2121 8.27419 81.6969 8.27419Z"
      fill="#525498"
    />
    <path
      d="M141.818 7.8871C145.455 7.8871 148.267 9.62904 150.303 13.0645C150.982 14.4194 151.321 15.871 151.321 17.4194V17.7581C151.224 18.4355 150.885 18.7742 150.448 18.7742H134.545C135.273 21.2419 136.63 22.9839 138.667 24.0968C139.83 24.5323 140.945 24.7742 141.964 24.7742C143.176 24.7742 144.485 24.4355 145.939 23.7097H146.23C146.812 23.8065 147.103 24.0968 147.103 24.5323V24.7258C147.103 25.6452 145.503 26.2742 142.351 26.7097H141.867C138.473 26.7097 135.758 25.1129 133.721 21.9677C132.897 20.4677 132.509 18.9194 132.509 17.3226C132.509 13.8871 134.158 11.129 137.406 9.09678C138.909 8.2742 140.412 7.8871 141.818 7.8871ZM134.4 16.9355H149.333C149.333 15.5323 148.703 13.9839 147.491 12.2903C145.794 10.6936 144.097 9.91936 142.448 9.91936H141.576C139.103 9.91936 137.067 11.129 135.467 13.5484C134.885 14.4677 134.545 15.629 134.4 16.9355Z"
      fill="#525498"
    />
    <path
      d="M177.261 0.870975H177.455C178.036 0.96775 178.327 1.20969 178.327 1.54839V8.37098H181.77L182.4 8.2742C182.788 8.2742 183.03 8.61291 183.224 9.29033C183.224 9.67743 182.982 9.96775 182.449 10.1613L181.479 10.0645H178.327V20.9516C178.327 22.9839 179.055 24.2903 180.509 24.7742L182.206 24.9194H183.03C183.37 24.9194 183.612 25.2097 183.758 25.8387C183.661 26.4194 183.37 26.7581 182.885 26.7581H181.333C179.297 26.7581 177.746 25.6452 176.776 23.371C176.63 22.8387 176.533 22.1613 176.533 21.4355V10.0645H174.642L173.576 10.1613C173.042 9.96775 172.8 9.67743 172.8 9.29033C172.897 8.70969 173.333 8.37098 174.061 8.37098H174.255H176.533V1.98388C176.582 1.25807 176.824 0.870975 177.261 0.870975Z"
      fill="#525498"
    />
    <path
      d="M214.594 7.93549C218.133 7.93549 220.945 9.67742 223.03 13.1613C223.661 14.5645 224 16.0161 224 17.5161V25.8871C224 26.3226 223.709 26.6129 223.079 26.7097H223.03C222.642 26.7097 222.352 26.4677 222.158 26.0323V26.0806L222.255 24.2903V23.0806C219.685 25.5 217.212 26.7581 214.933 26.7581H214.303C211.103 26.7581 208.485 25.1613 206.352 21.9677C205.576 20.4194 205.188 18.871 205.188 17.3226C205.188 13.8387 206.885 11.0806 210.327 9.04839C211.83 8.2742 213.236 7.93549 214.594 7.93549ZM207.03 17.1774V17.371C207.03 20.4194 208.582 22.7419 211.636 24.2903C212.606 24.629 213.527 24.8226 214.303 24.8226H214.885C217.455 24.8226 219.588 23.5645 221.236 21C221.818 19.7903 222.158 18.629 222.158 17.5161V17.2258C222.158 14.2742 220.703 12 217.794 10.4516C216.776 9.96774 215.709 9.7742 214.642 9.7742H214.545C211.442 9.7742 209.115 11.371 207.515 14.6129C207.224 15.629 207.03 16.5 207.03 17.1774Z"
      fill="#525498"
    />
    <path
      d="M47.1757 26.6613C42.0363 26.6613 37.8666 22.5 37.8666 17.371C37.8666 12.2419 42.0363 8.08064 47.1757 8.08064C52.3151 8.08064 56.4848 12.2419 56.4848 17.371C56.5333 22.5 52.3151 26.6613 47.1757 26.6613ZM47.1757 9.87096C43.0545 9.87096 39.6606 13.2097 39.6606 17.371C39.6606 21.4839 43.006 24.871 47.1757 24.871C51.2969 24.871 54.6909 21.5323 54.6909 17.371C54.6909 13.2581 51.3454 9.87096 47.1757 9.87096Z"
      fill="#525498"
    />
    <path
      d="M124.364 57.4839C123.879 57.4839 123.442 57.4355 123.055 57.2903C122.618 57.1452 122.279 56.9516 121.988 56.6613C121.697 56.4194 121.455 56.0807 121.309 55.7419C121.261 55.5968 121.261 55.4516 121.309 55.3548C121.358 55.2581 121.503 55.1613 121.648 55.1129C121.745 55.0645 121.891 55.1129 121.988 55.1613C122.085 55.2097 122.182 55.3065 122.279 55.4516C122.376 55.6452 122.521 55.8387 122.715 55.9839C122.909 56.129 123.152 56.2742 123.442 56.371C123.733 56.4678 124.024 56.5161 124.364 56.5161C124.752 56.5161 125.091 56.4678 125.382 56.3226C125.673 56.2258 125.915 56.0323 126.109 55.8387C126.303 55.5968 126.4 55.3548 126.4 55.0161C126.4 54.5807 126.255 54.2419 125.915 53.9516C125.624 53.6613 125.091 53.4194 124.412 53.371C123.491 53.2742 122.812 52.9839 122.279 52.5484C121.745 52.1129 121.503 51.5807 121.503 50.9032C121.503 50.4194 121.648 50.0323 121.891 49.6936C122.133 49.3548 122.521 49.1129 122.958 48.9194C123.394 48.7258 123.927 48.6774 124.509 48.6774C124.945 48.6774 125.333 48.7258 125.673 48.871C126.012 49.0161 126.303 49.1613 126.545 49.4032C126.788 49.6452 127.03 49.8871 127.176 50.1774C127.273 50.3226 127.321 50.4678 127.273 50.6129C127.273 50.7581 127.176 50.8548 127.079 50.9032C126.933 51 126.788 51 126.642 50.9516C126.497 50.9032 126.4 50.8065 126.303 50.7097C126.206 50.5161 126.061 50.3226 125.867 50.1774C125.673 50.0323 125.479 49.9355 125.285 49.8387C125.042 49.7419 124.8 49.6936 124.461 49.6936C123.927 49.6936 123.442 49.7903 123.103 50.0323C122.715 50.2742 122.57 50.6129 122.57 51.0484C122.57 51.2903 122.618 51.4839 122.764 51.7258C122.861 51.9194 123.103 52.1129 123.394 52.2581C123.685 52.4032 124.121 52.5 124.703 52.5484C125.576 52.6452 126.255 52.9355 126.739 53.371C127.224 53.8065 127.467 54.3871 127.467 55.1129C127.467 55.5484 127.37 55.8871 127.224 56.1774C127.079 56.4678 126.836 56.7581 126.545 56.9516C126.255 57.1452 125.915 57.3387 125.576 57.4355C125.188 57.4355 124.8 57.4839 124.364 57.4839Z"
      fill="#525498"
    />
    <path
      d="M131.442 57.1452C131.297 57.1935 131.2 57.1935 131.103 57.1452C131.006 57.0968 130.909 57 130.812 56.9032L128.291 52.0161C128.242 51.871 128.194 51.7742 128.242 51.629C128.291 51.5323 128.388 51.4355 128.533 51.3871C128.679 51.3387 128.776 51.3387 128.921 51.3871C129.018 51.4355 129.115 51.5323 129.164 51.629L131.588 56.5161C131.636 56.6613 131.685 56.7581 131.636 56.9032C131.636 57 131.539 57.0968 131.442 57.1452ZM130.133 60C130.036 60 129.988 60 129.891 59.9516C129.6 59.8065 129.503 59.6129 129.649 59.3226L133.091 51.629C133.236 51.3387 133.43 51.2903 133.721 51.3871C134.012 51.5323 134.109 51.7258 133.964 52.0161L130.521 59.7097C130.473 59.9032 130.327 60 130.133 60Z"
      fill="#525498"
    />
    <path
      d="M135.612 57.3871C135.467 57.3871 135.321 57.3387 135.224 57.2419C135.127 57.1452 135.079 57 135.079 56.8548V51.8226C135.079 51.6774 135.127 51.5323 135.224 51.4355C135.321 51.3387 135.467 51.2903 135.612 51.2903C135.758 51.2903 135.903 51.3387 136 51.4355C136.097 51.5323 136.145 51.6774 136.145 51.8226V56.8548C136.145 57 136.097 57.1452 136 57.2419C135.854 57.3387 135.758 57.3871 135.612 57.3871ZM140.218 57.3871C140.073 57.3871 139.927 57.3387 139.83 57.2419C139.733 57.1452 139.685 57 139.685 56.8548V54.0968C139.685 53.6613 139.588 53.3226 139.442 53.0323C139.297 52.7419 139.054 52.5484 138.812 52.4032C138.521 52.2581 138.23 52.2097 137.891 52.2097C137.551 52.2097 137.261 52.2581 137.018 52.4032C136.776 52.5484 136.533 52.6936 136.388 52.9355C136.242 53.1774 136.145 53.4194 136.145 53.6613H135.467C135.467 53.1774 135.612 52.7903 135.806 52.4032C136.048 52.0161 136.339 51.7742 136.727 51.5323C137.115 51.3387 137.551 51.1936 138.036 51.1936C138.521 51.1936 139.006 51.2903 139.394 51.5323C139.782 51.7258 140.121 52.0645 140.364 52.5C140.606 52.9355 140.703 53.4194 140.703 54.0484V56.8065C140.703 56.9516 140.654 57.0968 140.558 57.1936C140.461 57.3387 140.364 57.3871 140.218 57.3871Z"
      fill="#525498"
    />
    <path
      d="M145.309 57.4355C144.727 57.4355 144.194 57.2903 143.758 57.0484C143.321 56.7581 142.933 56.4194 142.691 55.9355C142.448 55.4516 142.303 54.9677 142.303 54.3871C142.303 53.8065 142.448 53.2742 142.691 52.7903C142.933 52.3065 143.273 51.9677 143.758 51.7258C144.194 51.4839 144.727 51.3387 145.309 51.3387C145.745 51.3387 146.133 51.4355 146.521 51.5807C146.909 51.7742 147.2 52.0161 147.491 52.3548C147.588 52.4516 147.636 52.5484 147.588 52.6936C147.539 52.7903 147.491 52.9355 147.345 52.9839C147.248 53.0323 147.152 53.0807 147.006 53.0323C146.909 53.0323 146.764 52.9355 146.715 52.8387C146.327 52.4032 145.842 52.2097 145.261 52.2097C144.873 52.2097 144.533 52.3065 144.242 52.5C143.952 52.6936 143.709 52.9355 143.564 53.2742C143.418 53.6129 143.321 53.9516 143.321 54.3871C143.321 54.8226 143.418 55.1613 143.564 55.5C143.758 55.8387 144 56.0807 144.291 56.2742C144.582 56.4678 144.97 56.5645 145.358 56.5645C145.6 56.5645 145.842 56.5161 146.085 56.4677C146.279 56.4194 146.473 56.2742 146.667 56.129C146.764 56.0323 146.909 55.9839 147.006 55.9839C147.103 55.9839 147.2 56.0323 147.297 56.0807C147.394 56.1774 147.491 56.2742 147.491 56.4194C147.491 56.5161 147.442 56.6613 147.345 56.7581C146.764 57.1936 146.133 57.4355 145.309 57.4355Z"
      fill="#525498"
    />
    <path
      d="M149.721 55.1129C149.576 55.1129 149.43 55.0645 149.333 54.9678C149.236 54.871 149.188 54.7258 149.188 54.5807V49.2581C149.188 49.1129 149.236 48.9678 149.333 48.871C149.43 48.7742 149.576 48.7258 149.721 48.7258C149.867 48.7258 150.012 48.7742 150.109 48.871C150.206 48.9678 150.255 49.1129 150.255 49.2581V54.5807C150.255 54.7258 150.206 54.871 150.109 54.9678C150.012 55.0645 149.915 55.1129 149.721 55.1129ZM149.721 57.3871C149.576 57.3871 149.43 57.3387 149.333 57.242C149.236 57.1452 149.188 57 149.188 56.8549V51.8226C149.188 51.6774 149.236 51.5323 149.333 51.4355C149.43 51.3387 149.576 51.2903 149.721 51.2903C149.867 51.2903 150.012 51.3387 150.109 51.4355C150.206 51.5323 150.255 51.6774 150.255 51.8226V56.8549C150.255 57 150.206 57.1452 150.109 57.242C150.012 57.3387 149.915 57.3871 149.721 57.3871ZM154.376 57.3871C154.23 57.3871 154.085 57.3387 153.988 57.242C153.891 57.1452 153.842 57 153.842 56.8549V54.0968C153.842 53.6613 153.745 53.3226 153.6 53.0323C153.455 52.742 153.212 52.5484 152.97 52.4032C152.679 52.2581 152.388 52.2097 152.049 52.2097C151.709 52.2097 151.418 52.2581 151.176 52.4032C150.933 52.5484 150.691 52.6936 150.545 52.9355C150.4 53.1774 150.303 53.4194 150.303 53.6613H149.624C149.624 53.1774 149.77 52.7903 149.964 52.4032C150.206 52.0161 150.497 51.7742 150.885 51.5323C151.273 51.3387 151.709 51.1936 152.194 51.1936C152.679 51.1936 153.164 51.2903 153.552 51.5323C153.939 51.7258 154.279 52.0645 154.521 52.5C154.764 52.9355 154.861 53.4194 154.861 54.0484V56.8065C154.861 56.9516 154.812 57.0968 154.715 57.1936C154.618 57.3387 154.521 57.3871 154.376 57.3871Z"
      fill="#525498"
    />
    <path
      d="M157.285 57.3871C157.139 57.3871 156.994 57.3387 156.897 57.2419C156.8 57.1452 156.751 57.0484 156.751 56.8548V51.8226C156.751 51.6774 156.8 51.5323 156.897 51.4355C156.994 51.3387 157.091 51.2903 157.285 51.2903C157.479 51.2903 157.576 51.3387 157.673 51.4355C157.77 51.5323 157.818 51.629 157.818 51.8226V56.8548C157.818 57 157.77 57.1452 157.673 57.2419C157.576 57.3387 157.43 57.3871 157.285 57.3871ZM157.236 53.6129C157.236 53.1774 157.333 52.7419 157.576 52.4032C157.77 52.0645 158.061 51.7742 158.448 51.5806C158.836 51.3871 159.224 51.2903 159.661 51.2903C160.048 51.2903 160.388 51.3387 160.582 51.4839C160.776 51.629 160.873 51.7742 160.824 51.9677C160.776 52.0645 160.727 52.1613 160.679 52.2097C160.63 52.2581 160.533 52.3064 160.436 52.3064C160.339 52.3064 160.242 52.3064 160.145 52.3064C159.709 52.2097 159.273 52.2581 158.933 52.3548C158.594 52.4516 158.303 52.5968 158.109 52.8387C157.915 53.0806 157.818 53.371 157.818 53.7097H157.236V53.6129Z"
      fill="#525498"
    />
    <path
      d="M164.315 57.4355C163.733 57.4355 163.2 57.2903 162.715 57.0484C162.23 56.8065 161.891 56.4194 161.648 55.9839C161.406 55.5484 161.261 55.0161 161.261 54.3871C161.261 53.8065 161.406 53.2742 161.648 52.7903C161.891 52.3548 162.279 51.9677 162.715 51.7258C163.2 51.4839 163.685 51.3387 164.315 51.3387C164.945 51.3387 165.43 51.4839 165.867 51.7258C166.303 51.9677 166.691 52.3548 166.933 52.7903C167.176 53.2742 167.321 53.8065 167.321 54.3871C167.321 54.9677 167.176 55.5 166.933 55.9839C166.691 56.4677 166.303 56.8065 165.867 57.0484C165.479 57.2903 164.945 57.4355 164.315 57.4355ZM164.315 56.5161C164.703 56.5161 165.091 56.4194 165.382 56.2258C165.673 56.0323 165.915 55.7903 166.109 55.4516C166.303 55.1129 166.351 54.7742 166.351 54.3387C166.351 53.9032 166.254 53.5645 166.109 53.2258C165.915 52.8871 165.673 52.6452 165.382 52.4516C165.091 52.2581 164.703 52.1613 164.315 52.1613C163.927 52.1613 163.539 52.2581 163.248 52.4516C162.958 52.6452 162.715 52.8871 162.521 53.2258C162.327 53.5645 162.279 53.9032 162.279 54.3387C162.279 54.7742 162.376 55.1129 162.521 55.4516C162.715 55.7903 162.958 56.0323 163.248 56.2258C163.588 56.4194 163.927 56.5161 164.315 56.5161Z"
      fill="#525498"
    />
    <path
      d="M172.024 57.3871C171.83 57.3871 171.685 57.2903 171.588 57.1452C171.491 57 171.491 56.8065 171.539 56.6129L174.691 49.1129C174.788 48.871 174.982 48.7742 175.176 48.7742C175.418 48.7742 175.564 48.871 175.661 49.1129L178.812 56.6613C178.909 56.8548 178.861 57 178.764 57.1936C178.667 57.3387 178.521 57.4355 178.327 57.4355C178.23 57.4355 178.133 57.3871 178.036 57.3387C177.939 57.2903 177.891 57.1936 177.842 57.0968L174.982 50.129H175.321L172.461 57.0968C172.412 57.1936 172.364 57.2903 172.267 57.3387C172.218 57.3387 172.121 57.3871 172.024 57.3871ZM172.655 55.4032L173.042 54.5323H177.455L177.842 55.4032H172.655Z"
      fill="#525498"
    />
    <path
      d="M182.594 57.4355C182.012 57.4355 181.479 57.2903 181.042 57.0484C180.606 56.7581 180.218 56.4194 179.976 55.9355C179.733 55.4516 179.588 54.9677 179.588 54.3871C179.588 53.8065 179.733 53.2742 179.976 52.7903C180.218 52.3065 180.558 51.9677 181.042 51.7258C181.479 51.4839 182.012 51.3387 182.594 51.3387C183.03 51.3387 183.418 51.4355 183.806 51.5807C184.194 51.7742 184.485 52.0161 184.776 52.3548C184.873 52.4516 184.921 52.5484 184.873 52.6936C184.824 52.7903 184.776 52.9355 184.63 52.9839C184.533 53.0323 184.436 53.0807 184.291 53.0323C184.194 53.0323 184.049 52.9355 184 52.8387C183.612 52.4032 183.127 52.2097 182.546 52.2097C182.158 52.2097 181.818 52.3065 181.527 52.5C181.236 52.6936 180.994 52.9355 180.849 53.2742C180.703 53.6129 180.606 53.9516 180.606 54.3871C180.606 54.8226 180.703 55.1613 180.849 55.5C181.042 55.8387 181.285 56.0807 181.576 56.2742C181.867 56.4678 182.255 56.5645 182.642 56.5645C182.885 56.5645 183.127 56.5161 183.37 56.4677C183.564 56.4194 183.758 56.2742 183.952 56.129C184.049 56.0323 184.194 55.9839 184.291 55.9839C184.388 55.9839 184.485 56.0323 184.582 56.0807C184.679 56.1774 184.776 56.2742 184.776 56.4194C184.776 56.5161 184.727 56.6613 184.63 56.7581C184.049 57.1936 183.37 57.4355 182.594 57.4355Z"
      fill="#525498"
    />
    <path
      d="M189.042 57.4355C188.509 57.4355 188.024 57.2903 187.588 57.0484C187.152 56.8064 186.812 56.4194 186.57 55.9355C186.327 55.4516 186.182 54.9677 186.182 54.3871C186.182 53.8064 186.327 53.2742 186.57 52.7903C186.861 52.3064 187.2 51.9677 187.685 51.6774C188.17 51.3871 188.655 51.2903 189.236 51.2903C189.818 51.2903 190.352 51.4355 190.788 51.6774C191.224 51.9677 191.612 52.3064 191.855 52.7903C192.145 53.2742 192.242 53.7581 192.242 54.3387L191.855 54.4355C191.855 55.0161 191.709 55.5 191.467 55.9355C191.224 56.371 190.885 56.7581 190.448 57C190.109 57.2903 189.624 57.4355 189.042 57.4355ZM189.236 56.5161C189.624 56.5161 189.964 56.4193 190.303 56.2258C190.594 56.0323 190.836 55.7903 191.03 55.4516C191.224 55.1129 191.321 54.7742 191.321 54.3387C191.321 53.9032 191.224 53.5645 191.03 53.2258C190.836 52.8871 190.594 52.6452 190.303 52.4516C190.012 52.2581 189.624 52.1613 189.236 52.1613C188.848 52.1613 188.509 52.2581 188.17 52.4516C187.83 52.6452 187.588 52.8871 187.442 53.2258C187.248 53.5645 187.152 53.9032 187.152 54.3387C187.152 54.7258 187.248 55.1129 187.442 55.4516C187.636 55.7903 187.879 56.0323 188.17 56.2258C188.461 56.4193 188.848 56.5161 189.236 56.5161ZM191.758 57.3871C191.612 57.3871 191.467 57.3387 191.37 57.2419C191.273 57.1452 191.224 57 191.224 56.8548V55.1129L191.418 54.0484L192.242 54.3871V56.9032C192.242 57.0484 192.194 57.1935 192.097 57.2903C192 57.3871 191.903 57.3871 191.758 57.3871Z"
      fill="#525498"
    />
    <path
      d="M196.703 57.4355C196.121 57.4355 195.588 57.2903 195.152 57.0484C194.667 56.7581 194.327 56.4194 194.036 55.9355C193.745 55.4516 193.649 54.9678 193.649 54.3871C193.649 53.8065 193.794 53.2742 194.036 52.8387C194.279 52.3549 194.618 52.0161 195.055 51.7258C195.491 51.4355 195.976 51.3387 196.509 51.3387C196.945 51.3387 197.382 51.4355 197.77 51.6291C198.158 51.8226 198.449 52.0645 198.691 52.4032V49.3549C198.691 49.2097 198.739 49.0645 198.836 48.9678C198.933 48.871 199.079 48.8226 199.224 48.8226C199.37 48.8226 199.515 48.871 199.612 48.9678C199.709 49.0645 199.758 49.2097 199.758 49.3549V54.4355C199.758 55.0161 199.612 55.5484 199.37 55.9839C199.079 56.4678 198.739 56.8065 198.255 57.0968C197.818 57.2903 197.285 57.4355 196.703 57.4355ZM196.703 56.5161C197.091 56.5161 197.43 56.4194 197.77 56.2258C198.061 56.0323 198.303 55.7903 198.497 55.4516C198.691 55.1129 198.788 54.7742 198.788 54.3387C198.788 53.9032 198.691 53.5645 198.497 53.2258C198.303 52.8871 198.061 52.6452 197.77 52.4516C197.479 52.2581 197.091 52.1613 196.703 52.1613C196.315 52.1613 195.976 52.2581 195.636 52.4516C195.297 52.6452 195.055 52.8871 194.909 53.2258C194.715 53.5645 194.618 53.9032 194.618 54.3387C194.618 54.7258 194.715 55.1129 194.909 55.4516C195.103 55.7903 195.345 56.0323 195.636 56.2258C195.927 56.4194 196.315 56.5161 196.703 56.5161Z"
      fill="#525498"
    />
    <path
      d="M204.558 57.4355C203.976 57.4355 203.394 57.2903 202.958 57.0484C202.473 56.8065 202.133 56.4194 201.842 55.9839C201.6 55.5 201.455 55.0161 201.455 54.3871C201.455 53.8065 201.6 53.2742 201.842 52.7903C202.085 52.3548 202.424 51.9677 202.909 51.7258C203.345 51.4839 203.879 51.3387 204.461 51.3387C205.042 51.3387 205.527 51.4839 205.915 51.7258C206.352 51.9677 206.642 52.3065 206.885 52.7903C207.127 53.2258 207.224 53.7581 207.224 54.3387C207.224 54.4839 207.176 54.5807 207.079 54.6774C206.982 54.7742 206.885 54.8226 206.739 54.8226H202.182V54H206.739L206.255 54.3387C206.255 53.9516 206.158 53.6129 206.012 53.2742C205.867 52.9839 205.673 52.7419 205.382 52.5484C205.091 52.3548 204.8 52.3065 204.412 52.3065C203.976 52.3065 203.636 52.4032 203.345 52.5968C203.055 52.7903 202.812 53.0323 202.667 53.371C202.521 53.7097 202.424 54.0968 202.424 54.4839C202.424 54.871 202.521 55.2581 202.715 55.5968C202.909 55.9355 203.152 56.1774 203.491 56.371C203.83 56.5645 204.218 56.6613 204.606 56.6613C204.848 56.6613 205.091 56.6129 205.333 56.5161C205.576 56.4194 205.77 56.3226 205.915 56.2258C206.012 56.129 206.109 56.129 206.255 56.129C206.352 56.129 206.497 56.1774 206.545 56.2258C206.642 56.3226 206.739 56.4194 206.739 56.5645C206.739 56.7097 206.691 56.8065 206.594 56.9032C206.352 57.0968 206.061 57.2419 205.673 57.3871C205.285 57.3387 204.897 57.4355 204.558 57.4355Z"
      fill="#525498"
    />
    <path
      d="M209.115 57.3871C208.97 57.3871 208.824 57.3387 208.727 57.2419C208.63 57.1452 208.582 57 208.582 56.8548V51.871C208.582 51.7258 208.63 51.5806 208.727 51.4839C208.824 51.3871 208.97 51.3387 209.115 51.3387C209.261 51.3387 209.406 51.3871 209.503 51.4839C209.6 51.5806 209.649 51.7258 209.649 51.871V56.8548C209.649 57 209.6 57.1452 209.503 57.2419C209.406 57.3387 209.261 57.3871 209.115 57.3871ZM212.897 57.3871C212.752 57.3871 212.606 57.3387 212.509 57.2419C212.412 57.1452 212.364 57 212.364 56.8548V53.7097C212.364 53.2258 212.267 52.8871 211.976 52.5968C211.733 52.3548 211.394 52.2097 211.006 52.2097C210.618 52.2097 210.279 52.3548 209.988 52.6452C209.745 52.9355 209.6 53.2742 209.6 53.7097H208.97C208.97 53.2258 209.067 52.8387 209.261 52.4516C209.455 52.0645 209.697 51.8226 210.036 51.5806C210.376 51.3871 210.764 51.2903 211.2 51.2903C211.636 51.2903 212.024 51.3871 212.315 51.5806C212.655 51.7742 212.897 52.0645 213.091 52.4032C213.285 52.7419 213.382 53.1774 213.382 53.7097V56.9032C213.382 57.0484 213.333 57.1935 213.236 57.2903C213.188 57.3387 213.042 57.3871 212.897 57.3871ZM216.63 57.3871C216.485 57.3871 216.339 57.3387 216.242 57.2419C216.145 57.1452 216.097 57 216.097 56.8548V53.7097C216.097 53.2258 216 52.8871 215.709 52.5968C215.467 52.3548 215.127 52.2097 214.739 52.2097C214.352 52.2097 214.012 52.3548 213.721 52.6452C213.479 52.9355 213.333 53.2742 213.333 53.7097H212.461C212.461 53.2258 212.558 52.7903 212.752 52.4516C212.945 52.0645 213.236 51.7742 213.624 51.5806C214.012 51.3871 214.4 51.2903 214.885 51.2903C215.321 51.2903 215.709 51.3871 216 51.5806C216.339 51.7742 216.582 52.0645 216.776 52.4032C216.97 52.7419 217.067 53.1774 217.067 53.7097V56.9032C217.067 57.0484 217.018 57.1935 216.921 57.2903C216.921 57.3387 216.776 57.3871 216.63 57.3871Z"
      fill="#525498"
    />
    <path
      d="M221.382 57.1452C221.236 57.1935 221.139 57.1935 221.042 57.1452C220.945 57.0968 220.849 57 220.752 56.9032L218.23 52.0161C218.182 51.871 218.133 51.7742 218.182 51.629C218.23 51.5323 218.327 51.4355 218.473 51.3871C218.618 51.3387 218.715 51.3387 218.861 51.3871C218.958 51.4355 219.055 51.5323 219.103 51.629L221.527 56.5161C221.576 56.6613 221.624 56.7581 221.576 56.9032C221.576 57 221.479 57.0968 221.382 57.1452ZM220.073 60C219.976 60 219.927 60 219.83 59.9516C219.539 59.8065 219.442 59.6129 219.588 59.3226L223.03 51.629C223.176 51.3387 223.37 51.2903 223.661 51.3871C223.952 51.5323 224.049 51.7258 223.903 52.0161L220.461 59.7097C220.412 59.9032 220.267 60 220.073 60Z"
      fill="#525498"
    />
  </svg>
</template>

<script>
export default {
  name: "Logo",
};
</script>

<style scoped></style>


import { Options, Vue } from "vue-class-component";
import MapyQr from "@/components/MapyQr.vue";
import ContactForm from "@/components/ContactForm.vue";

@Options({
  name: "ContactView",
  components: { ContactForm, MapyQr },
})
export default class ContactView extends Vue {}


import { Options, Vue } from "vue-class-component";

@Options({
  name: "HomeCarousel",
  props: {
    id: {
      required: true,
    },
    images: Array,
  },
})
export default class HomeCarousel extends Vue {
  images!: Array<string>;
  id!: string;

  carousel = null;
}

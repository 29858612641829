<template>
  <div class="g-recaptcha" ref="recaptcha"></div>
</template>

<script>
export default {
  name: "vueRecaptcha",
  data() {
    return {
      recaptcha: null,
    };
  },
  props: {
    siteKey: {
      type: String,
      default: "",
    },
    scriptId: {
      type: String,
      default: "recaptcha-script",
    },
    size: {
      type: String,
      required: false,
      default: "normal",
    },
    theme: {
      type: String,
      required: false,
      default: "light",
    },
  },
  emits: {
    verify(response) {
      return !!response;
    },
    expire: null,
    fail: null,
  },
  methods: {
    renderRecaptcha() {
      this.recaptcha = window.grecaptcha.render(this.$refs.recaptcha, {
        sitekey: this.siteKey,
        theme: this.theme,
        size: this.size,
        tabindex: this.tabindex,
        callback: (response) => this.$emit("verify", response),
        "expired-callback": () => this.$emit("expire"),
        "error-callback": () => this.$emit("fail"),
      });
    },
    reset() {
      window.grecaptcha.reset(this.recaptcha);
    },
  },
  mounted() {
    if (window.grecaptcha == null) {
      new Promise((resolve) => {
        window.recaptchaReady = function () {
          resolve();
        };

        const scriptTag = window.document.createElement("script");
        scriptTag.id = this.scriptId;
        scriptTag.setAttribute(
          "src",
          "https://www.google.com/recaptcha/api.js?onload=recaptchaReady&render=explicit"
        );

        window.document.head.appendChild(scriptTag);
      }).then(() => {
        this.renderRecaptcha();
      });
    } else {
      this.renderRecaptcha();
    }
  },
};
</script>

<style scoped></style>


import { Options, Vue } from "vue-class-component";
import ContactForm from "@/components/ContactForm.vue";

@Options({
  name: "JoinUsView",
  components: { ContactForm },
})
export default class JoinUsView extends Vue {
  selected: string | null = null;

  selectTeam(team: string): void {
    this.$router.push({ name: "teams", params: { teamId: team } });
  }
}


import { Options, Vue } from "vue-class-component";

import { store as $store } from "@/store";
import { GetterTypes } from "@/store/getters";
import Team from "@/types/Team";

import HomeCarousel from "@/components/HomeCarousel.vue";

@Options({
  name: "Home",
  components: {
    HomeCarousel,
  },
})
export default class Home extends Vue {
  showCaseImages: Array<string> = [
    "./assets/show-case/show-case-1.jpg",
    "./assets/show-case/show-case-2.jpg",
    "./assets/show-case/show-case-3.jpg",
  ];

  get teams(): Team[] {
    return $store.getters[GetterTypes.teams];
  }
}

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import TeamView from "@/views/TeamView.vue";
import AboutView from "@/views/AboutView.vue";
import ContactView from "@/views/ContactView.vue";
import CoachView from "@/views/CoachView.vue";
import JoinUsView from "@/views/JoinUsView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    name: "home",
    path: "/",
    component: Home,
  },
  {
    name: "teams",
    path: "/team/:teamId",
    component: TeamView,
    props: true,
  },
  {
    name: "aboutUs",
    path: "/o-nas",
    component: AboutView,
  },
  {
    name: "contact",
    path: "/kontakt",
    component: ContactView,
  },
  {
    name: "coachTeam",
    path: "/treneri",
    component: CoachView,
  },
  {
    name: "joinUs",
    path: "/pridej-se-k-nam",
    component: JoinUsView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;

import { MutationTree } from "vuex";
import _keyBy from "lodash/keyBy";
import Team from "@/types/Team";
import { State } from "@/store/state";

export enum MutationType {
  SetTeams = "SET_TEAMS",
  SetSelectedTeam = "SET_SELECTED_TEAM",
}

export type Mutations = {
  [MutationType.SetTeams](state: State, teams: Team[]): void;
  [MutationType.SetSelectedTeam](state: State, team: Team | null): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationType.SetTeams](state, teams) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state.teams = _keyBy(teams, "id") || {};
  },
  [MutationType.SetSelectedTeam](state, team) {
    state.selectedTeam = team;
  },
};

import axios from "./request";

interface ContactMessage {
  name: string;
  email?: string;
  phone?: string;
  message: string;
  gRecaptchaResponse: string;
}

interface ApiResponse {
  error?: string;
}

class ApiService {
  sendContactMessage(payload: ContactMessage) {
    return axios
      .post("/api.php", payload)
      .then((res) => res.data as ApiResponse);
  }
}

export default new ApiService();

import Team from "@/types/Team";

const teams: Team[] = [
  {
    id: "juniorky",
    barva: "#8D566E",
    jmeno: "Juniorky",
    trener: "Dina Fuchsová",
    komunikace: {
      instagram: "https://www.instagram.com/teamkometajunior/",
      facebook: "https://www.facebook.com/profile.php?id=61555397120486&sk=about",
    },
    obrazekUpoutavky: "/assets/teams/team-juniorky.jpg",
    obrazekObsahu: "/assets/teams/juniorky/team-juniorky-page.jpg",
    obsah:
      '<div class="row">\n' +
      '          <div class="col-md-5 col-sm-12 mb-4">\n' +
      '            <img src="/assets/teams/juniorky/juniorky-1.png" class="img-fluid" alt="Juniorky" />\n' +
      "          </div>\n" +
      "\n" +
      '          <div class="col-md-7 col-sm-12 mb-4">\n' +
      '            <p class="text-justify">Sezóna 2022/2023 je pro některé z nás již osmou společnou sezónou.</p>\n' +
      '            <p class="text-justify">Loňská sezóna byla již naše třetí v juniorské kategorii, a konečně jsme se mohli opět vrátit na závodní led, a to hned na dvou zahraničních závodech a dvou domácích. První domácí závod v Jihlavě byl z hlediska výsledku náš nejúspěšnější, kdy při plné účasti českých týmů jsme si odvezli zlatou medaili.</p>\n' +
      '            <p class="text-justify">Za juniory jsme poprvé závodili před třemi lety. V naší první juniorské sezóně jsme s krátkým programem na téma armády a volnou jízdou v duchu mumie získali mnoho cenných zkušeností i z velkých zahraničních závodů.</p>\n' +
      '            <p class="text-justify">V druhé sezóně jsme se opřeli o naši týmovou sílu a disciplínu a trénovali v nelehkých podmínkách covidových opatření. Byl to rok plný netradičních zážitků.</p>\n' +
      '            <p class="text-justify">Nyní se už těšíme na výzvy nadcházející sezóny!</p>\n' +
      '            <p class="text-justify"><span class="text-decoration-underline">Výsledky 2021/2022</span> <br />Óbuda Cup: 2. místo<br />Jihlavský ježek: 1. místo<br />ISU Mozart CUP: 5.místo<br />MČR Pardubice: 2. místo</p>\n' +
      '            <p><a class="btn kometa-btn kometa-juniorky-bg" href="/pridej-se-k-nam">Přidej se k juniorkám</a></p>' +
      "          </div>\n" +
      "        </div>\n" +
      "\n" +
      '        <div class="row">\n' +
      '          <div class="col-md-7 col-sm-12 mb-4">\n' +
      '            <h3 class="h3">Naše historie a úspěchy</h3>\n' +
      '            <p class="text-justify">V prvních dvou závodních sezónách jsme startovali v kategorii mladších žáků. V této kategorii jsme se stali mistry ČR a přivezli jsme několik medailí z mezinárodních závodů.</p>\n' +
      '            <p class="text-justify">Další tři sezóny jsme soutěžili v kategorii žáků. V roce 2017 jsme se stali vícemistry ČR a v roce 2018 jsme na mistrovství obsadili třetí místo. V sezóně 2019 jsme postoupili do kategorie advanced novice, ve které jsme započali soutěžení v počtu 16 bruslařů, a tak se o krok přiblížili k juniorské kategorii. A byl to začátek úspěšný. Vyhráli jsme mistroství ČR a těšili jsme se i z úspěchů ze zahraničních závodů.</p>\n' +
      "          </div>\n" +
      "\n" +
      '          <div class="col-md-5 col-sm-12 mb-4">\n' +
      '            <img src="/assets/teams/juniorky/juniorky-2.png" class="img-fluid" alt="Juniorky" />\n' +
      "          </div>\n" +
      "        </div>",
    clenky: [
      {
        id: 1,
        jmeno: "Kateřina Janovská „C“ (Kačka)",
        obrazek: "/assets/teams/juniorky/clenky/Kacka-Janovska.jpeg",
        od: "2013",
        popis:
          "Na synchru miluji týmovou práci a svobodu, kterou zažívám pokaždé co vejdu na led.",
      },
      {
        id: 2,
        jmeno: "Kitty Nedvědová „C“",
        obrazek: "/assets/teams/juniorky/clenky/Kitty-Nedvedova.jpeg",
        od: "2014",
        popis:
          "Na bruslení mám ráda, že mě dokáže nabít pozitivní energií a na ledě a mezi holkama se cítím prostě jako já :)",
      },
      {
        id: 4,
        jmeno: "Aneta Müllerová",
        obrazek: "/assets/teams/juniorky/clenky/Aneta-Mullerova.jpeg",
        od: "2021",
        popis:
          "Krasobruslení jsem milovala od malička, ale teprve v Kometě jsem poznala sílu týmového ducha.",
      },
      {
        id: 5,
        jmeno: "Anika Rabová",
        obrazek: "/assets/teams/juniorky/clenky/Anika-Rabova.jpeg",
        od: "2017",
        popis:
          "Na synchru mám ráda, že je to týmový sport a že je tu super parta.",
      },
      {
        id: 6,
        jmeno: "Barbara Pilousová",
        obrazek: "/assets/teams/juniorky/clenky/Barbara-Pilousova.jpeg",
        od: "2018",
        popis: "",
      },
      {
        id: 7,
        jmeno: "Dominika Jotovová",
        obrazek: "/assets/teams/juniorky/clenky/Dominika-Jotovova.jpeg",
        od: "2021",
        popis: "",
      },
      {
        id: 8,
        jmeno: "Edita Cejpková (Edit)",
        obrazek: "/assets/teams/juniorky/clenky/Edita-Cejpkova.jpeg",
        od: "2012",
        popis:
          "Krasobruslení je obecně krásný sport, mám ráda tu rutinu, atmosféru okolo závodu a celkově ten pocit, kdy na ledě častokrát zapomínám na okolní dění.",
      },
      {
        id: 9,
        jmeno: "Farah Shakerová",
        obrazek: "/assets/teams/juniorky/clenky/Farah-Shakerova.jpeg",
        od: "2022",
        popis: "",
      },
      {
        id: 10,
        jmeno: "Hedvika Fundová",
        obrazek: "/assets/teams/juniorky/clenky/Hedvika-Fundova.jpeg",
        od: "2013",
        popis: "",
      },
      {
        id: 11,
        jmeno: "Kornelia Gareeva (Korni)",
        obrazek: "/assets/teams/juniorky/clenky/Kornelia-Gareeva.jpeg",
        od: "2020",
        popis:
          "Na synchru mám ráda týmovou spolupráci a dosahování společných cílů.",
      },
      {
        id: 12,
        jmeno: "Magda Hrubá (Majda)",
        obrazek: "/assets/teams/juniorky/clenky/Majda-Hruba.jpeg",
        od: "2010",
        popis:
          "Na synchru mám nejraději, že v ničem nejsem sama a mám s kým sdílet zážitky.",
      },
      {
        id: 13,
        jmeno: "Matylda Prušinovská",
        obrazek: "/assets/teams/juniorky/clenky/Matylda-Prusinovska.jpeg",
        od: "2018",
        popis: "",
      },
      {
        id: 14,
        jmeno: "Nicole Luxová",
        obrazek: "/assets/teams/juniorky/clenky/Nikol-Luxova.jpeg",
        od: "2015",
        popis:
          "Synchro je krásný sport. Na tréninky chodím ráda nejen kvůli bruslení, ale i kvůli dobrému kolektivu.",
      },
      {
        id: 15,
        jmeno: "Sára Zenková",
        obrazek: "/assets/teams/juniorky/clenky/Sara-Zenkova.jpeg",
        od: "2022",
        popis: "",
      },
      {
        id: 16,
        jmeno: "Tereza Masničaková",
        obrazek: "/assets/teams/juniorky/clenky/Tereza-Masnicakova.jpeg",
        od: "2021",
        popis: "",
      },
      {
        id: 17,
        jmeno: "Tereza Vrsecká",
        obrazek: "/assets/teams/juniorky/clenky/Tereza-Vrsecka.jpeg",
        od: "2021",
        popis: "",
      },
      {
        id: 18,
        jmeno: "Veronika Buzková",
        obrazek: "/assets/teams/juniorky/clenky/Veronika-Buzkova.jpeg",
        od: "2017",
        popis:
          "Na synchru se mi nejvíc líbí, že je tam skvělý tým, trenérky a tréninky jsou zábavné.",
      },
      {
        id: 19,
        jmeno: "Vilma Martínková",
        obrazek: "/assets/teams/juniorky/clenky/Vilma-Martinkova.jpeg",
        od: "2013",
        popis:
          "Na bruslení mám nejradši podporu od ostatních a pocit svobody na ledě.",
      },
      {
        id: 20,
        jmeno: "Vojtěch Müller (Vojta)",
        obrazek: "/assets/teams/juniorky/clenky/Vojtech-Muller.jpeg",
        od: "2021",
        popis:
          "V Kometě jsou všichni fajn, je úžasné, že s takovými lidmi můžu bruslit ruku v ruce.",
      },
      {
        id: 21,
        jmeno: "Zuzana Králová (Zuzka)",
        obrazek: "/assets/teams/juniorky/clenky/Zuzka-Kralova.jpeg",
        od: "2012",
        popis:
          "Mám na synchru ráda kolektiv a vztahy mezi námi a samozřejmě tu zavodní atmosféru.",
      },
    ],
  },
  {
    id: "zacky",
    barva: "#D2563E",
    jmeno: "Žačky",
    trener: "Klaudie Palkosková",
    komunikace: {
      instagram: "https://www.instagram.com/teamkometa_novice/",
      facebook: "https://www.facebook.com/profile.php?id=61555397120486&sk=about",
    },
    obrazekUpoutavky: "/assets/teams/team-zacky.png",
    obrazekObsahu: "/assets/teams/zacky/team-zacky-page.png",
    obsah:
      '<div class="row">\n' +
      '          <div class="col-md-5 col-sm-12 mb-4">\n' +
      '            <img src="/assets/teams/zacky/zacky-1.png" class="img-fluid" alt="Žačky" />\n' +
      "          </div>\n" +
      "\n" +
      '          <div class="col-md-7 col-sm-12 mb-4">\n' +
      '            <p class="text-justify">Náš žákovský tým byl založen v roce 2017. Během tří sezón se nám podařilo od základu vybudovat úspěšný tým s velkým potenciálem do budoucna. V týmu bruslí děvčata ve věku 10 až 14 let, značná část týmu se zároveň věnuje sólovému krasobruslení, což je velkou předností tohoto týmu.</p>\n' +
      '            <p class="text-justify">Tréninky starších žaček jsou rozdělené na tréninky techniky bruslení a na synchro tréninky (děvčata ze sóla absolvují pouze synchro tréninky). Nezbytnou součástí tréninků je suchá příprava. Ta je zaměřená na taneční průpravu, protahování a posilování. V rámci suché přípravy probíhá i nácvik závodní jízdy.</p>\n' +
      '            <p class="text-justify">V loňské závodní sezóně 2021/2022 tým přešel z kategorie ml. žaček do kategorie advanced novice a v této kategorii bude pokračovat i letošní sezónu. Starším žačkám se start v této kategorii povedl na výbornou. Čtyři závody, čtyři zlaté medaile, z toho jedna z mezinárodního závodu organizovaného pod záštitou ISU. A to i přes to, že rozdíl v náročnosti mezi touto kategorií a kategorií mladších žaček je obrovská, jak v počtu bruslařů, kdy se jízda povinně předvádí v počtu 16ti bruslařů, tak v obtížnosti prvků. Týmu se tak podařilo po nucené závodní covidové pauze navázat na své úspěchy z kategorie mladších žaček.</p>\n' +
      '            <p class="text-justify">Největší radost z loňské sezóny máme ze zlaté medaile, kterou jsme si odvezly z mezinárodní soutěže Mozart Cup 2022, která se konala na konci ledna v Salzburgu. Nejlepší výkon jsme předvedly na naší poslední soutěži letošní sezóny, která se uskutečnila na začátku dubna v Jihlavě. Zároveň jsme zde dosáhly svého letošního bodového maxima, které bylo 61,14 bodu.</p>\n' +
      '            <p class="text-justify"><span class="text-decoration-underline">Výsledky 2021/2022</span><br />Jihlavský Ježek: 1. místo<br />Mozart cup: 1. místo<br />MČR: 1. místo<br /><a href="https://www.youtube.com/watch?v=lMhOY8nLVbA" title="Jihlavský ježek: 1. místo">Jihlavský ježek: 1. místo</a></p>\n' +
      '            <p><a class="btn kometa-btn kometa-zacky-bg" href="/pridej-se-k-nam">Přidej se k žačkám</a></p>' +
      "          </div>\n" +
      "        </div>\n" +
      "\n" +
      '        <div class="row">\n' +
      '          <div class="col-md-7 col-sm-12 mb-4">\n' +
      '            <h3 class="h3">Naše historie</h3>\n' +
      '            <p class="text-justify">První tři sezóny jsme startovaly v kategorii žačky mladší. Po prvním roce, kdy jsme se postupně učily základní formace a získávaly první zkušenosti se synchro závody, se začaly dostavovat výsledky naší práce. V následujících dvou závodních sezonách se nám velmi dařilo a obsazovaly jsme stupně vítězů jak na domácích, tak zahraničních závodech. Nejvíce si v této kategorii ceníme našeho vítězství na mezinárodní soutěži Second Cup of Dresden, která se uskutečnila na konci února 2020.</p>\n' +
      "          </div>\n" +
      "\n" +
      '          <div class="col-md-5 col-sm-12 mb-4">\n' +
      '            <img src="/assets/teams/zacky/zacky-2.png" class="img-fluid" alt="Žačky" />\n' +
      "          </div>\n" +
      "        </div>",
    clenky: [
      {
        id: 1,
        jmeno: "Anika Rabová „C“",
        obrazek: "/assets/teams/zacky/clenky/Anika-Rabova.jpeg",
        od: "2017",
        popis: "",
      },
      {
        id: 2,
        jmeno: "Veronika Buzková „C“",
        obrazek: "/assets/teams/zacky/clenky/Veronika-Buzkova.jpeg",
        od: "2017",
        popis: "",
      },
      {
        id: 3,
        jmeno: "Adéla Nicol Gabrielová",
        obrazek: "/assets/teams/zacky/clenky/Adela-Nicol-Gabrielova.jpeg",
        od: "2018",
        popis: "",
      },
      {
        id: 4,
        jmeno: "Adriana Němcová",
        obrazek: "/assets/teams/zacky/clenky/Adriana-Nemcova.jpeg",
        od: "2020",
        popis: "",
      },
      {
        id: 5,
        jmeno: "Agneta Rabová",
        obrazek: "/assets/teams/zacky/clenky/Agneta-Rabova.jpeg",
        od: "2018",
        popis: "",
      },
      {
        id: 6,
        jmeno: "Alyssa Balaria",
        obrazek: "/assets/teams/zacky/clenky/Alyssa-Balaria.jpeg",
        od: "2017",
        popis: "",
      },
      {
        id: 7,
        jmeno: "Alžběta Fairaisl",
        obrazek: "/assets/teams/zacky/clenky/Alzbeta-Fairaisl.jpeg",
        od: "2017",
        popis: "",
      },
      {
        id: 8,
        jmeno: "Barbara Pilousová",
        obrazek: "/assets/teams/zacky/clenky/Barbara-Pilousova.jpeg",
        od: "2018",
        popis: "",
      },
      {
        id: 9,
        jmeno: "Edita Luisa Gabrielová",
        obrazek: "/assets/teams/zacky/clenky/Edita-Luisa-Gabrielova.jpeg",
        od: "2018",
        popis: "",
      },
      {
        id: 10,
        jmeno: "Ema Vindeman",
        obrazek: "/assets/teams/zacky/clenky/Ema-Vindeman.jpeg",
        od: "2017",
        popis: "",
      },
      {
        id: 11,
        jmeno: "Kamila Slobodová",
        obrazek: "/assets/teams/zacky/clenky/Kamila-Slobodova.jpeg",
        od: "2017",
        popis: "",
      },
      {
        id: 12,
        jmeno: "Kasia Grace Curran",
        obrazek: "/assets/teams/zacky/clenky/Kasia-Grace-Curran.jpeg",
        od: "2021",
        popis: "",
      },
      {
        id: 13,
        jmeno: "Klaudie Udržalová",
        obrazek: "/assets/teams/zacky/clenky/Klaudie-Udrzalova.jpeg",
        od: "2020",
        popis: "",
      },
      {
        id: 14,
        jmeno: "Lucie Jakoube",
        obrazek: "/assets/teams/zacky/clenky/Lucie-Jakoube.jpeg",
        od: "2022",
        popis: "",
      },
      {
        id: 15,
        jmeno: "Magdaléna Kováčovská",
        obrazek: "/assets/teams/zacky/clenky/Magdalena-Kovacovska.jpeg",
        od: "2017",
        popis: "",
      },
      {
        id: 16,
        jmeno: "Natálie Šlégrová",
        obrazek: "/assets/teams/zacky/clenky/Natalie-Sleglova.jpeg",
        od: "2019",
        popis: "",
      },
      {
        id: 17,
        jmeno: "Romy Curran",
        obrazek: "/assets/teams/zacky/clenky/Romy-Curran.jpeg",
        od: "2021",
        popis: "",
      },
      {
        id: 18,
        jmeno: "Sofia Bogdanová",
        obrazek: "/assets/teams/zacky/clenky/Sofia-Bogdanova.jpeg",
        od: "2020",
        popis: "",
      },
      {
        id: 19,
        jmeno: "Sophie Müllerová",
        obrazek: "/assets/teams/zacky/clenky/Sophie-Mullerova.jpeg",
        od: "2017",
        popis: "",
      },
      {
        id: 20,
        jmeno: "Tereza Malevičová",
        obrazek: "/assets/teams/zacky/clenky/Tereza-Malevicova.jpeg",
        od: "2019",
        popis: "",
      },
      {
        id: 21,
        jmeno: "Tereza Vršecká",
        obrazek: "/assets/teams/zacky/clenky/Tereza-Vrsecka.jpeg",
        od: "2021",
        popis: "",
      },
      {
        id: 22,
        jmeno: "Valentyna Fukalová",
        obrazek: "/assets/teams/zacky/clenky/Valentyna-Fukalova.jpeg",
        od: "2022",
        popis: "",
      },
      {
        id: 23,
        jmeno: "Viktoria Illina",
        obrazek: "/assets/teams/zacky/clenky/Viktoria-Illina.jpeg",
        od: "2022",
        popis: "",
      },
    ],
  },
  {
    id: "mladsi-zacky",
    barva: "#E29C47",
    jmeno: "Mladší žačky",
    trener: "Klaudie Palkosková",
    komunikace: {
      facebook: "https://www.facebook.com/profile.php?id=61555397120486&sk=about",
    },
    obrazekUpoutavky: "/assets/teams/team-mladsi-zacky.jpg",
    obrazekObsahu: "/assets/teams/mladsi-zacky/team-mladsi-zacky-page.jpg",
    obsah:
      '<div class="row">\n' +
      '          <div class="col-md-5 col-sm-12 mb-4">\n' +
      '            <img src="/assets/teams/mladsi-zacky/mladsi-zacky-1.jpg" class="img-fluid kometa-image-rounded" alt="Mladší žačky" />\n' +
      "          </div>\n" +
      "\n" +
      '          <div class="col-md-7 col-sm-12 mb-4">\n' +
      '            <p class="text-justify">Náš nejmladší závodní tým vznikl na jaře roku 2020. Bohužel po pár měsících společné práce se díky „covidovým“ opatřením naše tréninky přesunuly jen do virtuální podoby. Od května 2021 se opět naplno trénuje a ml. žačky mají za sebou svou první závodní sezónu.</p>\n' +
      '            <p class="text-justify">V týmu bruslí děvčata ve věku 8 až 12 let. Tréninky probíhají třikrát týdně, zahrnují tréninky na ledě a suchou přípravu. Suchá příprava je zaměřená na taneční průpravu, protahování a posilování. V rámci suché přípravy probíhá i nácvik závodní jízdy. Na ledě se věnujeme jak technice bruslení, tak tréninku jednotlivých formací.</p>\n' +
      '            <p><a class="btn kometa-btn kometa-mladsi-zacky-bg" href="/pridej-se-k-nam">Přidej se k mladším žačkám</a></p>' +
      "          </div>\n" +
      "        </div>\n" +
      "\n" +
      '        <div class="row">\n' +
      '          <div class="col-md-7 col-sm-12 mb-4">\n' +
      '            <h3 class="h3">Naše úspěchy</h3>\n' +
      '            <p class="text-justify">První závodní sezóna byla úspěšná. Svou závodní premiéru děvčata absolvovala na mezinárodní soutěži Óbuda Cup 2021, který se konal v polovině prosince v Budapešti. Z Maďarska si tým odvezl bramborovou medaili a především první cenné závodní zkušenosti. Kromě této soutěže děvčata absolvovala další tři starty a závod od závodu si zlepšovala své osobní bodové maximum. Na únorovém Mistrovství České republiky obsadil tým v konkurenci šesti týmů úžasné druhé místo! Výsledky premiérové sezóny tohoto týmu jsou pro Kometu velký příslibem.</p>\n' +
      '            <p class="text-justify"><span class="text-decoration-underline">Výsledky 2021/2022</span>Óbuda Cup: 4. místo<br />Jihlavský Ježek: 2. místo<br />MČR Pardubice: 2. místo<br /><a href="https://www.youtube.com/watch?v=3ssnWVEABN8" title="Jihlavský Ježek (mezinárodní závod): 4. místo">Jihlavský Ježek (mezinárodní závod): 4. místo</a></p>\n' +
      "          </div>\n" +
      "        </div>\n",
    clenky: [
      {
        id: 1,
        jmeno: "Alžběta Vychodilová",
        obrazek: "/assets/teams/mladsi-zacky/clenky/Alzbeta-Vychodilova.jpeg",
        od: "2020",
        popis: "",
      },
      {
        id: 2,
        jmeno: "Anna Ambrožová",
        obrazek: "/assets/teams/mladsi-zacky/clenky/Anna-Ambrozova.jpeg",
        od: "2018",
        popis: "",
      },
      {
        id: 3,
        jmeno: "Aneta Žáčková",
        obrazek: "/assets/teams/mladsi-zacky/clenky/Aneta-Zackova.jpeg",
        od: "2018",
        popis: "",
      },
      {
        id: 4,
        jmeno: "Daniela Galambošová",
        obrazek: "/assets/teams/mladsi-zacky/clenky/Daniela-Galambosova.jpeg",
        od: "2020",
        popis: "",
      },
      {
        id: 5,
        jmeno: "Eliška Bělská",
        obrazek: "/assets/teams/mladsi-zacky/clenky/Eliska-Belska.jpeg",
        od: "2018",
        popis: "",
      },
      {
        id: 6,
        jmeno: "Josefína Fanthorpe",
        obrazek: "/assets/teams/mladsi-zacky/clenky/Josefina-Fanthorpe.jpeg",
        od: "2020",
        popis: "",
      },
      {
        id: 7,
        jmeno: "Julieta Wellartová",
        obrazek: "/assets/teams/mladsi-zacky/clenky/Julieta-Wellartova.jpeg",
        od: "2021",
        popis: "",
      },
      {
        id: 8,
        jmeno: "Kamila Slobodová",
        obrazek: "/assets/teams/mladsi-zacky/clenky/Kamila-Slobodova.jpeg",
        od: "2017",
        popis: "",
      },
      {
        id: 9,
        jmeno: "Magdaléna Moudrá",
        obrazek: "/assets/teams/mladsi-zacky/clenky/Magdalena-Moudra.jpeg",
        od: "2019",
        popis: "",
      },
      {
        id: 10,
        jmeno: "Mia Uhliarik",
        obrazek: "/assets/teams/mladsi-zacky/clenky/Mia-Uhliarik.jpeg",
        od: "2020",
        popis: "",
      },
      {
        id: 11,
        jmeno: "Natálie Matkovská",
        obrazek: "/assets/teams/mladsi-zacky/clenky/Natalie-Matkovska.jpeg",
        od: "2018",
        popis: "",
      },
      {
        id: 12,
        jmeno: "Olívie Fanthorpe",
        obrazek: "/assets/teams/mladsi-zacky/clenky/Olivie-Fanthorpe.jpeg",
        od: "2020",
        popis: "",
      },
      {
        id: 13,
        jmeno: "Sandra Nováková",
        obrazek: "/assets/teams/mladsi-zacky/clenky/Sandra-Novakova.jpeg",
        od: "2018",
        popis: "",
      },
      {
        id: 14,
        jmeno: "Valentýna Wellertová",
        obrazek: "/assets/teams/mladsi-zacky/clenky/Valentyna-Wellartova.jpeg",
        od: "2021",
        popis: "",
      },
    ],
  },
  {
    id: "pripravka",
    barva: "#EDD550",
    jmeno: "Přípravka",
    trener: "Jana Ficová",
    komunikace: {
      facebook: "https://www.facebook.com/profile.php?id=61555397120486&sk=about",
    },
    obrazekUpoutavky: "/assets/teams/team-pripravka.png",
    obrazekObsahu: "/assets/teams/pripravka/team-pripravka-page.png",
    obsah:
      '<div class="row">\n' +
      '          <div class="col-md-5 col-sm-12 mb-4">\n' +
      '            <img src="/assets/teams/pripravka/pripravka-1.jpg" class="img-fluid rounded-5" alt="Přípravka" />\n' +
      "          </div>\n" +
      "\n" +
      '          <div class="col-md-7 col-sm-12 mb-4">\n' +
      '            <p class="text-justify">V přípravce se učíme hravou formou základy bruslení, které se nijak neliší od klasického krasobruslení. Děti si osvojují základní dovednosti jako je stromeček, buřtíky, čáp, vozíček, nebo váha. Později se učí i složitější prvky jako obloučky, přešlapy, trojky apod. Trénink na ledě je doplněn o suchou přípravu, kde se věnujeme protahování, zpevnění středu těla a tréninku koordinace a rovnováhy.</p>\n' +
      '            <p class="text-justify">Do přípravky se mohou připojit děti ve věku 5 - 8 let (začátečníci i mírně pokročilé) průběžně po celou sezónu. Tréninky probíhají dvakrát až třikrát týdně. Přijď si to s námi kdykoli vyzoušet!</p>\n' +
      '            <p><a class="btn kometa-btn kometa-pripravka-bg" href="/pridej-se-k-nam">Přidej se k Přípravce</a></p>' +
      "          </div>\n" +
      "        </div>",
  },
];

export default teams;
